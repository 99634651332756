




























// This component is typically used as a dynamic component inside ModalWrapperComponent
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Competition } from '@/Competition';
import * as utils from '../utils';
import * as utils_time from '../utils_time';
import * as utils_tracker from '../utils_tracker';
import ModalWrapperComponent from '@/components/ModalWrapperComponent.vue';             // @ is an alias to /src

@Component({
  name: 'CompetitionForm',
  components: { ModalWrapperComponent }
})
export default class CompetitionFormComponent extends Vue {
  // prop reactive member variables publically available to the html template

  // public reactive member variables available to the html template
  public competitionId: string = null     // if editing
  public name: string = null
  public explanation: string = null
  public minNFTs: string = null
  public maxNFTs: string = null
  public startDateString: string = null
  public endDateString: string = null

  // public functions available to the html template
  public get loading(): boolean { return this.$store.state.loading }
  public get userId(): string { return this.$store.getters.userId }

  public onCancel() {
    this.$emit('close', false)
    this.clearFields()
  }
  public clearFields() {
    this.name = null
    this.explanation = null
    this.minNFTs = null
    this.maxNFTs = null
    this.startDateString = null
    this.endDateString = null    
  }
  public async onShow(dynamicProps: any) {
    this.clearFields()

    if (dynamicProps) {
      const competition: Competition = dynamicProps
      this.competitionId = competition.getIdAsString()
      this.name = competition.name
      this.explanation = competition.explanation
      this.minNFTs = competition.minNFTs ? competition.minNFTs.toString() : ''
      this.maxNFTs = competition.maxNFTs ? competition.maxNFTs.toString() : ''
      this.startDateString = utils_time.formatDateTimePT(competition.startDate)
      this.endDateString = utils_time.formatDateTimePT(competition.endDate)
    }
  }

  public async onOK() {
    // do hidden fields

    // tidy up form fields
    if (this.name) this.name = this.name.trim()
    if (this.explanation) this.explanation = this.explanation.trim()
    if (this.minNFTs) this.minNFTs = this.minNFTs.replace(/\D/g, "");
    if (this.maxNFTs) this.maxNFTs = this.maxNFTs.replace(/\D/g, "");
    if (this.startDateString) this.startDateString = this.startDateString.trim()
    if (this.endDateString) this.endDateString = this.endDateString.trim()

    // convert number fields
    const minNFTs: number = this.minNFTs ? Number(this.minNFTs) : null
    const maxNFTs: number = this.maxNFTs ? Number(this.maxNFTs) : null

    // validate form fields
    let abort: boolean = false
    if (!this.name || this.name.length === 0) { abort = true; utils.toast(this, 'Missing field', 'Please give your competition a name') }
    if (isNaN(minNFTs)) { abort = true; utils.toast(this, 'Invalid field', 'Minimum NFTs') }
    if (isNaN(maxNFTs)) { abort = true; utils.toast(this, 'Invalid field', 'Maximum NFTs') }

    if (abort) return

    // make POST or PUT call to server
    try {
      this.$store.commit('setLoading', { loading: true });
      let url: string = utils.getURLprefix() + '/api/competitions';
      if (this.competitionId) url += `/${this.competitionId}`
      const postData: any = { name: this.name, explanation: this.explanation, minNFTs: minNFTs, maxNFTs: maxNFTs, startDateString: this.startDateString, endDateString: this.endDateString} 
      const response: any = this.competitionId ? await utils.makePutCall(url, postData) : await utils.makePostCall(url, postData)
      if (!response || !response.data) throw(new Error('CompetitionFormComponent.onOK() got null response or null response.data'))

      // something is wrong with the alert
      if (response.data.error) {
        utils.toast(this, 'Error', response.data.error)
      }
      
      // success
      if (response.data.competition) {
        const competition: Competition = new Competition(response.data.competition)
        utils.toast(this, 'Success', `Competition ${ this.competitionId ? 'Updated' : 'Created' }.`)
        this.$emit('close', competition)
        this.clearFields()

        // utils_tracker.track(`Alert ${ this.alertId ? 'Updated' : 'Created' }`, { alertDescription: alert.description, user: this.user.getIdAsString() })
      }

    } catch (error) {
      utils.toast(this, 'System Error', 'Could not save competition')
      if (this.$store.state.devmode) console.error(error);
    } finally {
      this.$store.commit('setLoading', { loading: false });
    }

  }

  // private, non-reactive member variables

  // private functions not available directly to HTML template
  private async mounted() { 
    if (this.$store.state.devmode) console.log(`${this.$options.name} mounted()`)

    utils_tracker.page(this.$options.name)

    // utils_tracker.track(`Alert Form Shown`, {  user: this.user.getIdAsString() })

  }
  private async beforeDestroy() { if (this.$store.state.devmode) console.log(`${this.$options.name} beforeDestroy()`) }

}
